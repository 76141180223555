import * as React from 'react'
import PropTypes from 'prop-types'
import './styles.scss'

const ImageList = ({ className, children }) => {
    return (
        <div className={`c-image-list${className && ` ${className}`}`}>
            {children}
        </div>
    )
}

ImageList.propTypes = {
    /**
     * Optional CSS classes
     */
    className: PropTypes.string,
    /**
     * Inner child elements
     */
    children: PropTypes.node.isRequired
}

ImageList.defaultProps = {
    className: '',
    children: '<p>Images go here</p>'
}

export default ImageList
